import React from "react";
import { Link } from "react-router-dom";
import background from "../../../assets/images/contact/contact-us.jpg";
import services from "../../../data/services";
import "./servicedetail.css";

export default function MainSection({id}) {
  const selectedService=services[id-1]
  return (
    <section className="service-details pt-120 rpt-100 pb-100 rpb-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="service-sidebar rmb-55 wow fadeInLeft delay-0-2s">
              <div className="widget widget-services">
                <ul>
                  {services.map((service)=>(
                    <li className={`${service.id===id && 'active'}`}>
                    <Link to="/service-details" state={service.id}>{service.title}</Link>
                  </li>
                  ))}
                </ul>
              </div>
              <div className="contact_web ">
              <div
                className="widget  contact-info-widget contact-image-number style-two bgs-cover overlay"
                style={{ backgroundImage: `url(${background})`,  visibility: "visible",
                animationName: "fadeInUp", }}
              >
                <div className="contact-informations text-white" style={{paddingLeft:"20px"}}>
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li style={{display:"flex" ,alignItems:"center"}}>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+012-345-6789">+92 (307) 2469769</a>
                        </h5>
                      </div>
                    </li>
                    <li style={{display:"flex" ,alignItems:"center"}}>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:info@example.com">info@contrivers.dev</a>
                        </h5>
                      </div>
                    </li>
                    <li style={{display:"flex" ,alignItems:"center"}}>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:00 - 7:00</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="service-details-content wow fadeInRight delay-0-2s">
              <div className="image mb-40">
                <img
                width="770"
                height="415"
                  src={
                    require(`../../../assets/images/services/${selectedService.image}`)
                      .default
                  }
                  alt="Service Details"
                />
              </div>
              <h2>{selectedService.title}</h2>
              <p style={{textAlign:'justify'}}>
                {selectedService.description}
              </p>
              <ul className="list-style-three pt-15 pb-30">
                {selectedService.points.map((point)=>(
                  <li key={point.id}>{point.detail}</li>
                ))}
                
              </ul>
            </div>
          </div>
        </div>
      </div>


      {/*  */}
      <div className="mobile_con">
      <div className=" widget contact-info-widget contact-image-number style-two bgs-cover overlay"
                style={{ backgroundImage: `url(${background})`,  visibility: "visible",
                animationName: "fadeInUp", }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li style={{display:"flex" ,alignItems:"center"}}>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+012-345-6789">+92 (307) 2469769</a>
                        </h5>
                      </div>
                    </li>
                    <li style={{display:"flex" ,alignItems:"center"}}>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:info@example.com">info@contrivers.dev</a>
                        </h5>
                      </div>
                    </li>
                    <li style={{display:"flex" ,alignItems:"center"}}>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 9:00 - 7:00</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              </div>
    </section>
  );
}
