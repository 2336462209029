import { Link } from "react-router-dom";

function Navigation({ className }) {
  return (
    <>
      <div className={`restly-header-main-menu ${className || ""}`}>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          {/* <li>
            <Link to="#">
              Services <i className="fa fa-angle-down" />
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/service-one">Development</Link>
              </li>
              <li>
                <Link to="/service-two">Affiliate</Link>
              </li>
              
            </ul>
          </li> */}
          <li>
            <Link to="/portfolio">Portfolio</Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navigation;
