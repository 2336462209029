
const services=[
{
    id:1,
    title:"Web & Mobile App Development",
    image:"web-development.jpg",
    short_desc:"Building beautiful eye-catching responsive websites & apps that drive results & enhance user experience",
    icon_class:"flaticon flaticon-web-development-4",
    description:"Web development is an essential part of building a successful online presence for any business. It involves the creation of web-based applications and websites using various technologies such as HTML, CSS, and JavaScript. At our IT company, we offer comprehensive web development services tailored to meet the unique needs of our clients. Our team of experienced developers uses the latest technologies and best practices to create responsive, user-friendly, and visually appealing websites and applications. We also offer custom web development services to help our clients achieve their specific business goals, whether it's increasing traffic, improving user engagement, or boosting sales. From front-end design to back-end development and database integration, we work closely with our clients to ensure that their web development project is a success from start to finish.",
    points:[
        {id:1, detail:"Responsive and user-friendly web design"},
        {id:2, detail:"Full-stack web development services"},
        {id:3, detail:"Custom web application development for a range of industries"},
        {id:4, detail:"Robust testing and quality assurance for optimal website performance"}
    ]
},

{
    id:2,
    title:"Software Development",
    image:"software-development.jpg",
    short_desc:"Transforming complex business challenges into powerful software solutions that drive growth and innovation.",
    icon_class:"flaticon flaticon-computer",
    description:"Software development is at the core of our IT company, and we specialize in providing top-notch software solutions that cater to the needs of businesses across different industries. Our team of expert software developers uses the latest tools and technologies to create custom software solutions that meet the unique requirements of our clients. From desktop applications to web-based software and enterprise-level systems, we have the experience and expertise to deliver comprehensive software development services that drive business success. We work closely with our clients to ensure that their software development projects are aligned with their business objectives and deliver the desired results. Our agile development approach ensures that we deliver quality software solutions that are scalable, flexible, and cost-effective. With our focus on innovation and customer satisfaction, we are committed to delivering software solutions that help our clients stay ahead of the competition and achieve their business goals.",
    points:[
        {id:1, detail:"Full-stack software development services"},
        {id:2, detail:"Agile development methodology for efficient project management"},
        {id:3, detail:"Custom software solutions for a range of industries"},
        {id:4, detail:"Robust quality assurance and testing for flawless performance"}
    ]
},
{
    id:3,
    title:"Game Development",
    image:"game-development.jpg",
    short_desc:"Creating immersive and engaging gaming experiences with cutting-edge technology and innovative design.",
    icon_class:"flaticon flaticon-data",
    description:"Game development is a rapidly growing industry, and at our IT company, we offer top-of-the-line game development services that cater to the unique needs of our clients. Our team of experienced game developers uses the latest game engines and technologies to create immersive gaming experiences that engage and excite players. From 2D and 3D games to virtual reality and augmented reality games, we have the expertise and creativity to deliver games that are tailored to our clients' specific requirements. Our game development services cover everything from game concept and design to development, testing, and deployment. We work closely with our clients to ensure that their game development project is a success from start to finish. With our focus on innovation and customer satisfaction, we are committed to delivering game solutions that exceed our clients' expectations and help them achieve their business goals in the gaming industry.",
    points:[
        {id:1, detail:"2D and 3D game development for PC and mobile platforms"},
        {id:2, detail:"Cross-platform game development using popular game engines"},
        {id:3, detail:"Multiplayer game development with backend server integration"},
        {id:4, detail:"Custom game design, storyboarding, and character development"}
    ]
},

{
    id:4,
    title:"Artificial intelligence ",
    image:"machine-learning.jpg",
    short_desc:"Unlock the potential of Artificial Intelligence with our cutting-edge algorithms and solutions, empowering you to make intelligent business decisions..",
    icon_class:"flaticon flaticon-plan",
    description:"Machine learning has become a game-changing technology that has the potential to transform the way businesses operate. At our IT company, we offer comprehensive machine learning services that help our clients harness the power of machine learning to make informed decisions and gain a competitive edge. Our team of machine learning experts has extensive experience in developing and implementing custom machine learning algorithms that are tailored to our clients' specific needs. We offer a range of machine learning services, including data analytics, predictive modeling, natural language processing, and more. We work closely with our clients to understand their business objectives and deliver customized solutions that provide the best value for their investment. With our focus on innovation, accuracy, and customer satisfaction, we are committed to delivering machine learning services that help our clients achieve their business goals with confidence.",
    points:[
        {id:1, detail:"Custom machine learning algorithms development"},
        {id:2, detail:"Data analytics and predictive modeling"},
        {id:3, detail:"Natural language processing and computer vision"},
        {id:4, detail:"Personalized solutions tailored to your business objectives."}
    ]
}

]

export default services;