import emailjs from '@emailjs/browser';
import React, { useRef, useState } from "react";

export default function GetInTouchSection() {
  const form = useRef();
  const [status,setStatus]=useState("");
  const [isSend,setIsSend]=useState(false);
  const sendEmail = (e) => {
    e.preventDefault(); 
    emailjs.sendForm('service_kcy1tg8', 'template_hom1ny4', form.current, 'bJqKSTV-iKif7yV6b')
      .then(() => {
        setStatus("Email Sent!");
        setIsSend(true);
        form.current[0].value=""
        form.current[1].value=""
        form.current[2].value=""
      }, (error) => {
        setStatus("Error, Try Again!");
        setIsSend(false)
        form.current[0].value=""
        form.current[1].value=""
        form.current[2].value=""
        console.log(error)
      });
  };
  return (
    <section className="contact-four py-120 rpy-100">
      <div className="container">
        <div className="contact-inner-four box-shadow p-35 rpx-25 br-5 bg-white">
          <div className="row">
            <div className="col-lg-11">
              <div className="contact-section-form px-15 rpx-0 rmb-50 wow fadeInUp delay-0-2s animated">
                <div className="section-title mb-20">
                  <h2>Get In Touch</h2>
                </div>
                <form ref={form} onSubmit={sendEmail}
                    id="comment-form"
                    className="comment-form mt-35"
                    name="comment-form"
                   
                  >
                    <div className="row clearfix justify-content-center">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="name">
                            <i className="far fa-user"></i>
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            // value={formData.name}
                            // onChange={handleChange}
                            placeholder="Your Full Name"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="email">
                            <i className="far fa-envelope"></i>
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            // value={formData.email}
                            // onChange={handleChange}
                            placeholder="Your Email"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="message">
                            <i className="fas fa-pencil-alt"></i>
                          </label>
                          <textarea
                            name="message"
                            id="message"
                            className="form-control"
                            rows="4"
                            placeholder="Write Message"
                            required=""
                            // value={formData.message}
                            // onChange={handleChange}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <p className={isSend ? 'successMessage' : 'errorMessage'}>{status}</p>
                        <div className="form-group mb-0">
                          <button type="submit" value="Send" className="theme-btn">
                            Send Message
                          </button>
                          <button onClick={()=>{window.open("https://khalil-contrivers.zohobookings.com/#/customer/contrivers")}} type="button"   className="slotBtn ml-10">
                            Book a Slot
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
              </div>
            </div>
            {/* <div className="col-lg-5">
              <div
                className="contact-image-number style-two bgs-cover overlay wow fadeInUp delay-0-4s animated"
                style={{
                  backgroundImage: `url(${background})`,
                  visibility: "visible",
                  animationName: "fadeInUp",
                }}
              >
                <div className="contact-informations text-white">
                  <h3>Don't hesitate to contact us</h3>
                  <ul className="contact-info">
                    <li>
                      <i className="fas fa-phone-alt"></i>
                      <div className="content">
                        <span>Call Us</span>
                        <h5>
                          <a href="callto:+923364652799">+012-345-6789</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-envelope"></i>
                      <div className="content">
                        <span>Write to Us</span>
                        <h5>
                          <a href="mailto:info@contrivers.dev">info@contrivers.dev</a>
                        </h5>
                      </div>
                    </li>
                    <li>
                      <i className="fas fa-clock"></i>
                      <div className="content">
                        <span>Office hours</span>
                        <h5>Mon-Sat 11:00 - 8:00</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
