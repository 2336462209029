import React from "react";
import { Link } from "react-router-dom";
import services from "../../data/services";

function ServiceSection() {
  return (
    <section className="services-section pt-120 rpt-100 pb-90 rpb-70">
      <div className="container">
        <div className="row" style={{ alignItems:"center"}}>
          {services.map((service) => (
            <div key={service.id} className="col-xl-3 col-sm-6 ">
              <div className="service-box wow fadeInUp delay-0-2s">
                <div className="service-normal">
                  <div className="icon">
                    <i className={service.icon_class}></i>
                  </div>
                  <h6 style={{minHeight:"40px"}}>{service.title}</h6>
                  <p
                    style={{
                      minHeight: "180px",
                    }}>
                    {service.short_desc}{" "}
                  </p>
                  <a className="btn-circle" href="/service-details">
                    <i className="fas fa-long-arrow-alt-right"></i>
                  </a>
                </div>
                <div className="service-hover bg-blue text-white">
                  <h3>Preparing For Your Business Success With IT Solution</h3>
                  <Link
                    to="/service-details"
                    state={service.id}
                    className="theme-btn">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          ))}

          {/*
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-2s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-computer"></i>
                </div>
                <h6>Web Development</h6>
                <p>
                Building beautiful eyecatching responsive websites that drive results and enhance user experience.                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Preparing For Your Business Success With IT Solution</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisc</p>
                <a href="/service-details" className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-4s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-web-development-4"></i>
                </div>
                <h6>Mobile App Development</h6>
                <p>
                Developing custom mobile apps that connect businesses with their customers anytime, anywhere.                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Preparing For Your Business Success With IT Solution</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisc</p>
                <a href="/service-details" className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-6s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-web"></i>
                </div>
                <h6>Software Development</h6>
                <p>
                Transforming complex business challenges into powerful software solutions that drive growth and innovation.                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Preparing For Your Business Success With IT Solution</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisc</p>
                <a href="/service-details" className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div className="service-box wow fadeInUp delay-0-8s">
              <div className="service-normal">
                <div className="icon">
                  <i className="flaticon flaticon-data"></i>
                </div>
                <h6>Game Development</h6>
                <p>
                Creating immersive and engaging gaming experiences with cutting-edge technology and innovative design.                </p>
                <a className="btn-circle" href="/service-details">
                  <i className="fas fa-long-arrow-alt-right"></i>
                </a>
              </div>
              <div className="service-hover bg-blue text-white">
                <h3>Preparing For Your Business Success With IT Solution</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipisc</p>
                <a href="/service-details" className="theme-btn">
                  Learn More
                </a>
              </div>
            </div>
        </div> 
          */}
        </div>
      </div>
    </section>
  );
}

export default ServiceSection;
