/* eslint-disable */

import React from "react";
import LazyLoad from "react-lazyload";
import "./product.css";

export default function Products() {
  return (
    <div className="product-wrapper">
      <div className="product-header">
        We bring ideas to life—see for yourself
      </div>

      {/* Hemavi */}
      <div className="product_container">
        <div className="content">
          <LazyLoad offset={100} once>
            <img
              src={
                require(`../../assets/images/products/hemavilogo.svg`).default
              }
              alt="Project Logo"
              className="logo"
            />
          </LazyLoad>
          <h1 className="product_heading">Hemavi</h1>
          <p className="product_para">
            An algorithm based matchmaking that connects compatible roommates
            Based on lifestyle, interests and preferences
          </p>
          <p className="product_para">
            Explore available rooms in all of Sweden and Denmark and find your
            perfect roommate today
          </p>
        </div>
        <div className="images">
          <LazyLoad offset={100} once className="project-image-1-container">
            <img
              src={require(`../../assets/images/products/hemavi1.PNG`).default}
              alt="Project Image 1"
              className="project-image-1"
            />
          </LazyLoad>
          <LazyLoad
            height={200}
            offset={100}
            once
            className="project-image-2-container"
          >
            <img
              src={require(`../../assets/images/products/hemavi2.PNG`).default}
              alt="Project Image 2"
              className="project-image-2"
            />
          </LazyLoad>
        </div>
      </div>

      {/* Getinca */}
      <div className="product_container_2">
        <div className="images">
          <LazyLoad offset={100} once className="project-image-1-container">
            <img
              src={require(`../../assets/images/products/inca1.PNG`).default}
              alt="Project Image 1"
              className="project-image-1"
            />
          </LazyLoad>
          <LazyLoad offset={100} once className="project-image-2-container">
            <img
              src={require(`../../assets/images/products/inca2.PNG`).default}
              alt="Project Image 2"
              className="project-image-2"
            />
          </LazyLoad>
        </div>
        <div className="content">
          <LazyLoad offset={100} once>
            <img
              src={
                require(`../../assets/images/products/Inca-Logo-Dark.svg`)
                  .default
              }
              alt="Project Logo"
              className="logo"
            />
          </LazyLoad>
          <h1 className="product_heading">
            Inca - Turn missed calls into new customers
          </h1>
          <p className="product_para">
            Take advantage of the only channel with a 98% open rate. Get
            immediate responses and measurable results
          </p>
        </div>
      </div>

      {/* Trackrec */}
      <div className="product_container">
        <div className="content">
          <div>
            <LazyLoad offset={100} once>
              <img
                src={
                  require(`../../assets/images/products/trackreclogo.png`)
                    .default
                }
                alt="Project Logo"
                className="logo"
              />
            </LazyLoad>
          </div>
          <h1 className="product_heading">
            Trackrec - Streamline your hiring process as Sales Professionals
          </h1>
          <p className="product_para">
            A platform designed to showcase sales achievements, track and share
            sales performance metrics, and promote profiles to potential
            employers
          </p>
        </div>
        <div className="images">
          <LazyLoad offset={100} once className="project-image-1-container">
            <img
              src={
                require(`../../assets/images/products/trackrec1.PNG`).default
              }
              alt="Project Image 1"
              className="project-image-1"
            />
          </LazyLoad>
          <LazyLoad offset={100} once className="project-image-2-container">
            <img
              src={
                require(`../../assets/images/products/trackrec2.PNG`).default
              }
              alt="Project Image 2"
              className="project-image-2"
            />
          </LazyLoad>
        </div>
      </div>

      {/* WeCoach */}
      <div className="product_container_2">
        <div className="images">
          <LazyLoad offset={100} once className="project-image-1-container">
            <img
              src={require(`../../assets/images/products/wecoach2.png`).default}
              alt="Project Image 2"
              className="project-image-2"
            />
          </LazyLoad>
          <LazyLoad offset={100} once className="project-image-2-container">
            <img
              src={require(`../../assets/images/products/wecoach1.png`).default}
              alt="Project Image 1"
              className="project-image-1"
            />
          </LazyLoad>
        </div>
        <div className="content">
          <div>
            <LazyLoad offset={100} once>
              <img
                src={
                  require(`../../assets/images/products/wecoach_logo.png`)
                    .default
                }
                alt="Project Logo"
                className="logo"
              />
            </LazyLoad>
          </div>
          <h1 className="product_heading">
            WeCoach - Personalised, one-on-one lessons from top-notch, highly
            talented coaches
          </h1>
          <p className="product_para">
            Hire Gaming Coaches for 1-1 training & lessons and level up &
            compete against the best
          </p>
        </div>
      </div>
    </div>
  );
}
