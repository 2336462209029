import React from "react";
import { useLocation } from 'react-router-dom';

import Layouts from "../../helpers/Layouts";
import MainSection from "./MainSection";


export default function ServiceDetails() {
  const location=useLocation()
  return (
    <Layouts
      pageTitle="Service Details"
      // breadcrumbs={[
      //   { name: "Home", path: "/" },
      //   { name: "service-details", path: "/service-details" },
      // ]}
    >
      <MainSection id={location.state?location.state:1}/>
    </Layouts>
  );
}
