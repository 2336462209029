import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";
import background from "../../assets/images/contact/contact_image.jpg";
import HeaderHomeThree from "../partials/Headers/HeaderHomeThree";
import "./contact.css";

export default function Contact() {
  const form = useRef();
  const [status, setStatus] = useState("");
  const [isSend, setIsSend] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [allError, setAllError] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();
    const name = form.current[0].value;
    const email = form.current[1].value;
    const message = form.current[2].value;
    const htmlForm = form.current;

    if (name === "" && email === "" && message === "") {
      setAllError(true);
      setTimeout(() => {
        setAllError("");
      }, 5000);
      return;
    }
    if (name === "") {
      setNameError(true);
      setTimeout(() => {
        setNameError("");
      }, 5000);
      return;
    }
    if (email === "") {
      setEmailError(true);
      setTimeout(() => {
        setEmailError("");
      }, 5000);
      return;
    }
    if (message === "") {
      setMessageError(true);
      setTimeout(() => {
        setMessageError("");
      }, 5000);
      return;
    }

    emailjs
      .sendForm(
        "service_naey0na",
        "template_6a4zd5k",
        htmlForm,
        "TCw-SJc2po_5_MQIx"
      )
      .then(
        () => {
          setStatus("Email Sent!");
          setTimeout(() => {
            setStatus("");
          }, 5000);
          setIsSend(true);
          form.current[0].value = "";
          form.current[1].value = "";
          form.current[2].value = "";
        },
        (error) => {
          setStatus("Error, Try Again!");
          setIsSend(false);
          form.current[0].value = "";
          form.current[1].value = "";
          form.current[2].value = "";
          console.log(error);
        }
      );
  };
  
  return (
    <div  >

      <div className="contact_nav">
      <HeaderHomeThree />
      </div>
            <div className="contact_container" >
              <div className="contact_left" style={{flex:"1"}}>
                <div
                  className="contact-form-left bgs-cover h-100 wow fadeInLeft delay-0-2s"
                  style={{ backgroundImage: `url(${background})` }}>
                  <h2>Leave A Message</h2>
                </div>

             
                <a className="contrivers_heading" href="/">Contrivers</a>

                <div className="lahore_marker">Lahore</div>
                <div className="dubai_marker">Dubai</div>
                
              </div>

              <div className="contact_right" >
                <div className="">
                  <h3 className="comment-title mb-35">Send A Message</h3>
                  <p>
                    Contact us if you need any assistance or have any questions!
                  </p>
                  <form
                    ref={form}
                    onSubmit={sendEmail}
                    id="comment-form"
                    className="comment-form mt-35"
                    name="comment-form">
                    <div className="row clearfix justify-content-center">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="user_name">
                            <i className="far fa-user"></i>
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="user_name"
                            className="form-control"
                            placeholder="Your Full Name"
                            required=""
                          />
                        </div>
                        {nameError && (
                          <p className="errorMessage">please enter name</p>
                        )}
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="email">
                            <i className="far fa-envelope"></i>
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="user_email"
                            className="form-control"
                            placeholder="Your Email"
                            required=""
                          />
                        </div>
                        {emailError && (
                          <p className="errorMessage">
                            please enter your Email
                          </p>
                        )}
                      </div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="message">
                            <i className="fas fa-pencil-alt"></i>
                          </label>
                          <textarea
                            name="message"
                            id="message"
                            className="form-control"
                            rows="4"
                            placeholder="Write Message"
                            required=""
                          ></textarea>
                        </div>
                        {messageError && (
                          <p className="errorMessage">
                            please enter your message
                          </p>
                        )}
                        {allError && (
                          <p className="errorMessage">please fill all fields</p>
                        )}
                      </div>
                      <div className="col-sm-12">
                        <p
                          className={
                            isSend ? "successMessage" : "errorMessage"
                          }>
                          {status}
                        </p>
                        <div className="form-group mb-0">
                          <button
                            type="submit"
                            value="Send"
                            className="theme-btn">
                            Send Message
                          </button>
                          <button
                            onClick={() => {
                              window.open(
                                "https://khalil-contrivers.zohobookings.com/#/customer/contrivers"
                              );
                            }}
                            type="button"
                            className="slotBtn ml-10">
                            Book a Slot
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
    </div>
  );
}
