
import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import "./Testimonials.css";

const testimonials = [
  {
    name: 'Pascal Dettwiler',
    title: 'CEO Hemavi',
    quote: "I strongly recommend everybody to use Contriver's service for Web Development work. They are highly professional, always on time, great eye for detail, and most important, they take accountability for their work to an extent you rarely encounter, which is something we valued very highly. 10/10!",
  },
  {
    name: 'Rodney Pateal',
    quote: "They are skilled with several technologies & have helped us with SQL/NoSQL databases, Micro Services and Mobile development. I would re-hire them for my other projects.",
  },
  {
    name: 'Igor Maric',
    title: 'Manager @ The Ace :spades: VIP Mykonos',
    quote: "Contriver's team worked with us on creating an elaborate and fully customised CRM project on SuiteCRM / Sugar CRM that lasted exactly a year! We are extremely happy with the work and with the end result.",
  },
  {
    name: 'Ammon Collins',
    title: 'Founder @ INCA',
    quote: "Contriver's is an absolute DREAM team to work with! They understood and worked with us on every step of the way! Our hope is to work with them forever as we continue to grow. Their quality of work, availability, adherence to our schedule, communication and cooperation was STELLAR! I highly recommend them for your project or anything you need, you will not be disappointed!",
  },
  {
    name: 'Philip Nordenfelt',
    title: 'CEO RevHuntr',
    quote: "Contriver's team members are attentive, well-developed, professional and cares deeply about the craft of Software Engineering. I would highly recommend their team for any software engineering project.",
  },
];

export default function Testimonials() {
  const settings = {
      arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    className: 'react__slick__slider__parent',
  };

  return (
    <div className="slider">
      <h2 style={{ marginBottom: "30px",textAlign:"Center" }}>Here’s what our satisfied clients have to say about us</h2>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <p className="testimonial_quote">"{testimonial.quote}"</p>
            <h4>{testimonial.name}</h4>
            {testimonial.title && <h5>{testimonial.title}</h5>}
          </div>
        ))}
      </Slider>

    </div>
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
     style={style}
      className={`${className} custom-arrow custom-arrow-next`}
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
       style={style}
      className={`${className} custom-arrow custom-arrow-prev`}
      onClick={onClick}
    ></div>
  );
}
