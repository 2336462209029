import React from "react";
import Testimonials from "../../HomeOne/Testimonails";
import AboutSection from "../../HomeThree/AboutSection";
import Layouts from "../../helpers/Layouts";


export default function AboutOne() {
  return (
    <>
      <Layouts
        pageTitle="About Us"
        // breadcrumbs={[
        //   { name: "home", path: "/" },
        //   { name: "about", path: "/about-one" },
        // ]}
      >
        <AboutSection className="pb-240" />
        {/* <WorkProcessSection /> */}
        {/* <TestimonialSection /> */}
        <Testimonials/>
        {/* <GetInTouchSection /> */}
      </Layouts>
    </>
  );
}
