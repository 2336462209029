import BacktoTopCom from "../helpers/BackToTopCom";
import FooterHomeTwo from "../partials/Footers/FooterHomeTwo";
import HeaderHomeThree from "../partials/Headers/HeaderHomeThree";
import Hero from "./Hero";
import Products from "./Products";
import ServiceSection from "./ServiceSection";
import Testimonails from "./Testimonails";

function HomeOne() {
  return (
    <div style={{ overflowX: "hidden" }}>
      <HeaderHomeThree />
      <Hero />
      <ServiceSection />
      <Products />
      <Testimonails/>

      
      {/* <TestimonialSection /> */}
      {/* <FeatureSection /> */}
      {/* <WorkProcessSection /> */}
      {/* <PricingSection /> */}
      {/* <GallerySection /> */}
      <FooterHomeTwo />
      <BacktoTopCom />
    </div>
  );
}

export default HomeOne;
