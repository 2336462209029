import { Route, Routes } from "react-router-dom";
import AboutOne from "./components/AboutPages/AboutOne/index";
import Contact from "./components/Contact/index";
import CounterPage from "./components/CounterPage";
import FourZeroFour from "./components/FourZeroFour/index";
import HomeOne from "./components/HomeOne";
import PortfolioOne from "./components/PortfolioPages/PortfolioOne";
import PortfolioThree from "./components/PortfolioPages/PortfolioThree";
import PortfolioTwo from "./components/PortfolioPages/PortfolioTwo";
import PricingOne from "./components/PricingPages/PricingOne";
import PricingTwo from "./components/PricingPages/PricingTwo";
import ServiceDetails from "./components/ServicePages/ServiceDetails/index";
import ServiceOne from "./components/ServicePages/ServiceOne/index";
import ServiceTwo from "./components/ServicePages/ServiceTwo/index";
import TeamOne from "./components/TeamPages/TeamOne";
import ProtfolioDetails from "./components/TestimonialPages/TestimonialLayout";
import TestimonialOne from "./components/TestimonialPages/TestmonialOne";

export default function Routers() {
  return (
    <Routes>
      <Route exact path="/" element={<HomeOne />} />
      <Route exact path="/about" element={<AboutOne />} />
      <Route exact path="/service-one" element={<ServiceOne />} />
      <Route exact path="/service-two" element={<ServiceTwo />} />
      <Route exact path="/service-details" element={<ServiceDetails />} />
      <Route exact path="/portfolio" element={<PortfolioOne />} />
      <Route exact path="/portfolio-two" element={<PortfolioTwo />} />
      <Route exact path="/portfolio-three" element={<PortfolioThree />} />

      <Route exact path="/portfolio-details" element={<ProtfolioDetails />} />
      <Route exact path="/team-one" element={<TeamOne />} />
      <Route exact path="/testimonial-one" element={<TestimonialOne />} />
      <Route exact path="/pricing" element={<PricingOne />} />
      <Route exact path="/pricing-two" element={<PricingTwo />} />
      <Route exact path="/404" element={<FourZeroFour />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/counters" element={<CounterPage />} />
    </Routes>
  );
}
