import React, { useEffect } from "react";
import logo from "../../../../assets/images/logos/company-logo.png";
import StickyMenu from "../../../../lib/StickyMenu";
import Navigation from "../../../helpers/Navigation";
import MobileHeaderCom from "../../Mobile/MobileHeaderCom";

export default function HeaderHomeThree() {
  useEffect(() => {
    StickyMenu(".main-header");
  });
  return (
    <>
      <MobileHeaderCom logo={logo} />
      <header className="main-header header-three text-white">
        <div className=" ">
          <div className="container">
      
          </div>
        </div>
        <div className="header-upper">
          <div className="container clearfix">
            <div className="header-inner d-flex align-items-center">
              <div className="logo-outer">
                <div className="logo">
                  <a href="/">
                    <img src={logo} height={60} alt="Logo" title="Logo" />
                  </a>
                </div>
              </div>

              <div className="nav-outer clearfix d-flex align-items-center">
                <div className="main-menu navbar-expand-lg">
                  <Navigation />
                </div>
                <div className="menu-btn">
                  <a href="/contact" className="theme-btn">
                    Contact Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
