import React from "react";
import Avatar from "react-avatar";
import SliderCom from "../helpers/SliderCom";

export default function TestimonialSection() {
  const settings = {
    dots: false,
    infinite: true,
    // autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section
      className="testimonial-section bg-lighter pt-115 rpt-95 pb-120 rpb-100"
      id="testimonial-section"
    >
      <div className="container">
        
        
        <div className="testimonial-wrap">
          <SliderCom settings={settings}>
            <div className="testimonial-item wow fadeInLeft delay-0-2s">
              <div className="author-description">
              <Avatar name="Igor"  round size="58" color="gray"/>

               { 
               /* 
               <img
                  src={
                    require(`../../assets/images/testimonials/testi-author-1.jpg`)
                      .default
                  }
                  alt="Author"
                /> */ 
                } 
                <div className="designation" style={{marginLeft:"20px"}}>
                  <h5>Igor Maric</h5>
                  <span>CRM Application</span>
                </div>
                <i className="fas fa-quote-right"></i>
              </div>
              <p className="text-height">
              Farjad worked with us on creating an elaborate and fully customized CRM project on SuiteCRM / Sugar CRM that lasted exactly a year! We are extremely happy with the work and with the end result.
              </p>
            </div>
            <div className="testimonial-item wow fadeInRight delay-0-2s">
              <div className="author-description">
              <Avatar name="William"  round size="58" color="gray"/>

                <div className="designation" style={{marginLeft:"20px"}}>
                  <h5>William</h5>
                  <span>IOS Game Development</span>
                </div>
                <i className="fas fa-quote-right"></i>
              </div>
              <p className="text-height">
              Azha was an absolute DREAM to work with!, he understood and worked with us every step of the way! Our hope is to work with him forever as we continue to grow. His quality of work, availability, adherence to our schedule, communication and cooperation was STELLAR! I highly recommend him for your project or anything you need, you will not be disappointed!"
              </p>
            </div>
            <div className="testimonial-item">
              <div className="author-description">
              <Avatar name="Philip"  round size="58" color="gray"/>

                <div className="designation" style={{marginLeft:"20px"}}>
                  <h5>Philip</h5>
                  <span>Google sheets AddOn</span>
                </div>
                <i className="fas fa-quote-right"></i>
              </div>
              <p className="text-height">
              Khalil is one in a 1000 when it comes to freelancers. He is attentive, well-developed, professional and cares deeply about the craft of Software Engineering. He is capable alone and as a Team Lead. I would highly recommend Khalil for any software engineering project. It's been great working with you, friend
              </p>
            </div>
            <div className="testimonial-item">
              <div className="author-description">
              <Avatar name="Rodney"  round size="58" color="gray"/>

                <div className="designation" style={{marginLeft:"20px"}}>
                  <h5>Rodney Pateal</h5>
                  <span>Server Development</span>
                </div>
                <i className="fas fa-quote-right"></i>
              </div>
              <p className="text-height">
              Freelancer is skilled with several technologies. Even helped with NO SQL database, Micro Services and Mobile development. I would re hire him for my other project.
              </p>
            </div>
          </SliderCom>
        </div>
      </div>
    </section>
  );
}
