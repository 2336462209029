import React from "react";

export default function AboutSection({ className }) {
  return (
    <section className={`about-three py-120 rpy-100 ${className || ""}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-three-image rmb-60 mr-10 rmr-0 wow fadeInLeft delay-0-2s">
              <img
                src={
                  require(`../../assets/images/about/about-page-1.jpg`).default
                }
                alt="About"
              />
              <img
                src={
                  require(`../../assets/images/about/about-page-2.jpg`).default
                }
                alt="About"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content pl-70 rpl-0 wow fadeInRight delay-0-2s">
              <div className="section-title mb-35">
                <span className="sub-title">Tech Support For Business</span>
                <h2>
                Transform Your Ideas into Reality with Our Software Development Services.
                </h2>
              </div>
              <p>
              We designs, develops, and maintains computer software for various purposes such as business, entertainment, education, and communication.
              </p>
              <ul className="list-style-three mt-15">
                <li>Tailored application to meet the specific needs of an organization or individual.</li>
                <li>Scaleable as the business grows.</li>
                <li>Automate repetitive tasks, reduce errors, and streamline workflows.</li>
                <li>Protect sensitive data, and detect and respond to security threats.</li>
              </ul>
              {/* <a href="/about-one" className="theme-btn style-three mt-25">
                View details
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
