import React from "react";
import { Link } from "react-router-dom";
import Background from "../../../../assets/images/footer/footer-bg-map.png";
import logo from "../../../../assets/images/logos/company-logo.png";

export default function FooterHomeTwo({ className }) {
  return (
    <footer
      className={`main-footer footer-two bgs-cover text-white ${
        className || ""
      }`}
      style={{ backgroundImage: `url(${Background})` }}>
      <div className="container">
        {/* <div className="footer-top pt-75">
          <ul className="contact-info">
            <li>
              <i className="fas fa-phone-alt"></i>
              <div className="content">
                <span>Call Us </span>
                <h5>
                  <a href="callto:+012-345-6789">+92 (307) 2469769</a>
                </h5>
              </div>
            </li>
            <li>
              <i className="fas fa-envelope"></i>
              <div className="content">
                <span>Write to Us</span>
                <h5>
                  <a href="mailto:info@example.com">info@contrivers.dev</a>
                </h5>
              </div>
            </li>
            <li>
              <i className="fas fa-clock"></i>
              <div className="content">
                <span>Office hours</span>
                <h5>Mon-Sat 9:00 - 7:00</h5>
              </div>
            </li>
          </ul>
        </div> */}
        <div className="footer-widget-area pt-85 pb-20">
          <div className="row p-4 flex-column flex-sm-row justify-content-between">
            <div className="col-lg-4 col-sm-6 ml-n4 ml-sm-0">
              <div className="footer-widget about-widget ">
                <div className="footer-logo mb-35">
                  <a href="/">
                    <img width="150" height="70" src={logo} alt="Logo" />
                  </a>
                </div>
                <div className="text">
                  Transforming businesses through technology and innovation with
                  exceptional IT services and solutions.
                </div>
                <ul className="contact-info mt-20">
                  <li style={{marginTop:"8px"}}>
                    <i className="fas fa-map-marker-alt"></i>
                    <span>
                    Contrivers Office, Sector C, Bahria Town, Lahore
                    </span>
                  </li>
                  <li >
                    <i className="fas fa-map-marker-alt"></i>
                    <span style={{marginTop:"8px"}}>
                    Business Park - Dubai Silicon Oasis, UAE
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-6 ">
              <div className="footer-widget link-widget ml-20 rml-0">
                <h4 className="footer-title">Page Links</h4>
                <ul className="list-style-two">
                  <li>
                    <Link
                      to="/"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}>
                      About
                    </Link>
                  </li>
                  {/* <li>
                  <Link to="/service-one">Services</Link>
                  </li> */}
                  <li>
                    <Link
                      to="/portfolio"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}>
                      Portfolio
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}>
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-3 col-6">
              <div className="footer-widget link-widget ml-20 rml-0">
                <h4 className="footer-title">Explore</h4>
                <ul className="list-style-two">
                  <li>
                    <a href="#">Our Scientists</a>
                  </li>
                  <li>
                    <a href="#">Our Services</a>
                  </li>
                  <li>
                    <a href="#">Testimonials</a>
                  </li>
                  <li>
                    <a href="#">SaaS Solutions</a>
                  </li>
                  <li>
                    <a href="#">Case Studies</a>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-lg-3 col-sm-6 ">
              <div className="footer-widget newsletter-widget">
                {/* <h4 className="footer-title">Newsletter</h4>
                <p>
                Stay up-to-date with the latest news, insights, and innovations from our company by subscribing to our newsletter.


                </p> */}
                {/* <form action="#" method="post">
                  <input
                    type="email"
                    name="EMAIL"
                    placeholder="Your Email Address"
                    required
                  />
                  <button type="submit">
                    <i className="fa fa-location-arrow"></i>
                  </button>
                </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="copyright-inner pt-15">
            <div className="social-style-one mb-10">
              <a
                href="https://www.facebook.com/p/Contrivers-100063949239492/"
                target="_blank"
                rel="noreferrer">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/74702881/"
                target="_blank"
                rel="noreferrer">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
